html, body, #root {
  height: 100%;
  margin: 0;
  text-align: center;
  color: var(--text-color);
  background-color: var(--background-color);
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: var(--header-color);
  padding: 20px;
  color: #fff;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: auto;
  display: inline-flex;
}

nav ul li {
  margin: 0 15px;
}

nav a, a {
  color: var(--accent-color);
  transition: color 0.3s;
  text-decoration: none;
}

nav a:hover, a:hover {
  color: var(--hover-color);
}

main {
  flex: 1;
  padding: 20px;
}

section {
  margin: 50px 0;
  border-bottom: 1px solid #333;
  padding-bottom: 20px;
}

footer {
  background-color: #1f1f1f;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

:root {
  --background-color: #121212;
  --header-color: #1f1f1f;
  --text-color: #e0e0e0;
  --accent-color: #bb86fc;
  --hover-color: #ffb74d;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
  position: relative;
}

.tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  white-space: normal;
  max-width: 200px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

a:hover .tooltip {
  visibility: visible;
  opacity: 1;
}